import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../../components/layout";
import Headline from "../../components/headline";
import DynamicContent from "../../components/dynamicContent";
import { Grid, Typography, useScrollTrigger, Slide } from "@material-ui/core";
import { ContactSupportOutlined } from "@material-ui/icons";

// import useScrollTrigger from "@material-ui/core/useScrollTrigger";
const parse = require("html-react-parser");

const HideOnScroll = (props) => {
  const trigger = useScrollTrigger();
  return (
    <Slide in={!trigger}>
      <div>Hello</div>
    </Slide>
  );
};

const SexualOrientationPage = () => {
  const data = useStaticQuery(query);

  //   console.log(data.allStrapiGender.edges[0].node.Content);

  // const { slug } = data.allStrapiSexualOrientation.nodes[0];

  return (
    <Layout>
      {/* <Typography variant="h2">{data.strapiHomepage.hero.title}</Typography> */}

      {data.allStrapiSexualOrientation.nodes.map((el) => (
        <Grid item id={el.slug} key={`sexual-orientation__${el.slug}`}>
          <Grid container spacing={2}>
            <Grid item>
              <Headline headline={el.Headline} />
            </Grid>
            <Grid item>
              {el.Content.map((contentBlock) => (
                <DynamicContent block={contentBlock} />
              ))}
            </Grid>
          </Grid>

          {/* <h2
              dangerouslySetInnerHTML={{ __html: el.node.Headline.heading }}
            ></h2>
            <p
              dangerouslySetInnerHTML={{
                __html: el.node.Headline.textField,
              }}
            ></p> */}
        </Grid>
      ))}
    </Layout>
  );
};

const query = graphql`
  query {
    strapiHomepage {
      hero {
        title
      }
      seo {
        metaTitle
        metaDescription
        shareImage {
          publicURL
        }
      }
    }

    allStrapiSexualOrientation {
      nodes {
        slug
        name
        Headline {
          heading
          id
          textField
        }
        Content {
          tiktokUrl
          __typename
          videoUrl
          textField
          quoteText
          quoteAuthor
          musicUrl
          embedCode
          instagramUrl
          heading
          eventName
          eventDescription
          eventDate
          captionText
          blockName
          captionHeading
          artistName
          # id
          # SublineLink {
          #   authorPublication
          #   discoverMore
          # }
          Instagram {
            instagramUrl
          }
          Image {
            childCloudinaryAsset {
              fluid(maxWidth: 1024) {
                ...CloudinaryAssetFluid
              }
            }
          }
          flagImage {
            name
            childCloudinaryAsset {
              fluid(maxWidth: 1024) {
                ...CloudinaryAssetFluid
              }
            }
          }
        }
      }
      edges {
        node {
          Headline {
            heading
            id
            textField
          }
        }
      }
    }
    # allStrapiArticle(filter: { status: { eq: "published" } }) {
    #   edges {
    #     node {
    #       strapiId
    #       slug
    #       title
    #       category {
    #         name
    #       }
    #       image {
    #         childImageSharp {
    #           gatsbyImageData(width: 800, height: 500)
    #         }
    #       }
    #       author {
    #         name
    #         picture {
    #           childImageSharp {
    #             gatsbyImageData(width: 30, height: 30)
    #           }
    #         }
    #       }
    #     }
    #   }
    # }
  }
`;

export default SexualOrientationPage;
